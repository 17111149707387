<template>
  <div class="warpper">
    <slot></slot>
  </div>
</template>

<style scoped>
.warpper {
  margin: 4rem 2rem;
  background-color: rgb(255, 158, 60);
}
</style>